import $ from "jquery";

$.comments = {
  url: null,
  init: function (url) {
    this.url = url;

    $(".comments").on("click", "input.cancel", function () {
      var comment = $(this).parents(".comments__item");
      $(comment).children("form").remove();
      $(comment).removeClass("replying");
    });

    $(".comments").on("click", ".remove a", function () {
      $.ajax({
        url: this.href,
        context: this,
        beforeSend: function () {
          return confirm("Удалить комментарий?");
        },
        success: function () {
          $(this).parents(".comments__item").toggleClass("deleted").html("<p>Комментарий удален</p>");
        },
      });
      return false;
    });

    $(".comments").on("click", ".reply a", function () {
      $.comments.show_reply_form($(this));
      return false;
    });

    $(".comments").on("click", "p.can-reply", function () {
      $.comments.show_reply_form($(this));
    });

    $(".comments").on("keydown", "textarea", function (e) {
      if (e.ctrlKey && e.keyCode === 13) {
        $(e.target.form).submit();
      }
    });

    $(".comments").on("submit", "form.reply-form", function () {
      $(this).ajaxSubmit({
        type: "post",
        beforeSubmit: $.comments.validate,
        success: function (responseText, statusText, xhr, $form) {
          const comment = $form.parents(".comments__item");
          $(responseText).insertAfter(comment);
          $(comment).removeClass("replying");
          $form.remove();
        },
        error: function (responseText) {
          if (responseText.status === 403) {
            $("#comment_form input[type=submit]").attr("disabled", "disabled").val("Заблокировано");
            alert("Комментарий запрещён к публикации, попробуйте позже");
          }
        },
      });
      return false;
    });

    $(".comments").on("submit", "form.comments__form", function () {
      $(this).ajaxSubmit({
        type: "post",
        beforeSubmit: $.comments.validate,
        success: function (responseText) {
          $(responseText).appendTo(".comments ul");
          $(".comments__post").remove();
          $(".comments__empty").remove();
        },
        error: function (responseText) {
          if (responseText.status === 403) {
            $("#comment_form input[type=submit]").attr("disabled", "disabled").val("Заблокировано");
            alert("Комментарий запрещён к публикации.");
          }
        },
      });
      return false;
    });
  },

  show_reply_form: function (node) {
    var comment = $(node).parents(".comments__item");
    if (!$(comment).hasClass("replying")) {
      $(comment)
        .addClass("replying")
        .append(
          '<form action="' +
            this.url +
            '" method="POST" class="reply-form">' +
            '<input type="hidden" name="parent_id" value="' +
            $(comment).attr("rel") +
            '"/>' +
            '<textarea rows="3" cols="60" name="comment" placeholder="Введите текст комментария" required></textarea>' +
            '<input type="submit" value="Ответить" class="button">&nbsp;' +
            '<input type="button" value="Отмена" class="button hollow cancel">' +
            "</form>"
        )
        .find("textarea")
        .trigger("focus");
    }
  },

  validate: function (formData, jqForm) {
    for (var i = 0; i < formData.length; i++) {
      if (formData[i].name === "comment") {
        var val = $.trim(formData[i].value);
        if (val.length < 2) {
          alert("Введите текст комментария не менее 2-х знаков!");
          $(jqForm).find("textarea").trigger("focus");
          return false;
        }
      }
    }
  },
};
