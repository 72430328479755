import Axios from "axios";

class LikeButton {
    private readonly elm: HTMLAnchorElement;
    private score: number;
    private readonly url: string;

    constructor(elm: HTMLAnchorElement, score: number, url: string) {
        this.elm = elm;
        this.score = score;
        this.url = url;
        this.elm.addEventListener("click", this.onClick, { once: false });
    }

    public static init(selector: string): void {
        document.querySelectorAll(selector).forEach((elm: HTMLAnchorElement) => {
            const score = Number(elm.dataset?.score);
            const url = elm.dataset?.url;
            if (url) {
                new LikeButton(elm, score, url);
            }
        });
    }

    private onClick = (e): void => {
        e.preventDefault();

        Axios.post(this.url)
            .then((response) => {
                if (response.status == 200) {
                    this.score += 1;
                    this.elm.innerHTML = `<i class="icon icon-heart"></i>${this.score}`;
                }
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    alert("Вы уже голосовали!");
                } else {
                    alert("Произошла ошибка. Попробуйте повторить попытку позже.");
                }
            });
    };
}

export = LikeButton;
