import "modules/sentry";

import jQuery from "jquery";
import Foundation from "foundation-sites";

import "style/default.scss";

window.jQuery = jQuery;
window.$ = jQuery;

import "jquery-form";
import "jquery_metadata";

import "fancybox/dist/js/jquery.fancybox";
import "fancybox/dist/scss/jquery.fancybox.scss";

import "fotorama/fotorama";
import "fotorama/fotorama.css";

import "pannellum/src/js/libpannellum";
import "pannellum/src/js/pannellum";
import "pannellum/src/css/pannellum.css";

import Advert from "./modules/advert";
import BackToTop from "./modules/back-top";
import LikeButton from "./modules/like-button";
import onCopyAddLink from "./modules/on-copy";
import SlickSlider from "./modules/slick-slider";

import "./modules/comments";
import "./modules/social-likes";
import "./modules/video-player";

$(document).foundation();

if (!Foundation.MediaQuery.atLeast("medium")) {
    const $collapse = $("#collapse");
    const $button = $("#collapse_button");
    if ($collapse.children().length !== 0) {
        $button.addClass("visible");
        $button.on("click", (e) => {
            e.preventDefault();
            $button.toggleClass("expanded");
            $collapse.children(":not(#collapse_button)").stop().slideToggle("fast");
        });
    }
}

SlickSlider(".slider-news");

Advert.init("[data-bs-id]");

BackToTop.init();

LikeButton.init("[data-toggle=like]");

$("a[rel=fancybox]").fancybox();

$("#link_back").on("click", (e) => {
    e.stopPropagation();
});

if (!window.is_authenticated) {
    document.oncopy = onCopyAddLink;
}
