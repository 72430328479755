import axios from "axios";
import throttle from "lodash/throttle";

const BS_AJAX_URL = "https://bs.elec.ru/bs/place.php";
const BS_IFRAME_URL = "https://bs.elec.ru/bs/iframe.php";

enum AdvertType {
    AJAX,
    IFRAME,
}

class AdvertStatic {
    private readonly elm: HTMLElement;
    private readonly elmRect: DOMRect;
    private readonly elmType: AdvertType;
    private readonly elmWidth: string;
    private readonly elmHeight: string;
    private readonly elmClass: string | undefined;

    private readonly bsId: string;
    private isLoading: boolean;

    constructor(elm: HTMLElement) {
        if (!(this instanceof AdvertStatic)) {
            return new AdvertStatic(elm);
        }

        this.elm = elm;
        this.elmRect = elm.getBoundingClientRect();
        this.elmWidth = elm.dataset.bsWidth ?? "100%";
        this.elmHeight = elm.dataset.bsHeight ?? "90";
        this.elmClass = elm.dataset.bsClass;

        switch (elm.dataset?.bsType) {
            case "ajax":
                this.elmType = AdvertType.AJAX;
                break;
            case "iframe":
                this.elmType = AdvertType.IFRAME;
                break;
            default:
                this.elmType = AdvertType.IFRAME;
        }

        this.bsId = elm.dataset.bsId ?? "0";
        this.isLoading = false;

        if (this.isVisible()) {
            this.load();
        } else {
            window.addEventListener("scroll", this.handleScroll);
        }
    }

    private handleScroll = throttle((): void => {
        if (this.isVisible()) {
            this.load();
            window.removeEventListener("scroll", this.handleScroll);
        }
    }, 100);

    private isVisible(): boolean {
        if (!this.elm.offsetParent) {
            return false;
        }
        return this.elmRect.bottom < window.scrollY + window.innerHeight;
    }

    private async load(): Promise<void> {
        if (this.isLoading) {
            return Promise.reject();
        }

        this.isLoading = true;

        return this.elmType == AdvertType.IFRAME ? this.loadIFrame() : this.loadAjax();
    }

    private async loadAjax(): Promise<void> {
        const result = await axios.get(`${BS_AJAX_URL}?id=${this.bsId}`, {
            headers: {
                Accept: "text/html",
            },
            withCredentials: true,
        });

        if (result.data) {
            this.elm.innerHTML = result.data;

            if (this.elmClass) {
                this.elm.setAttribute("class", this.elmClass);
            }
        } else {
            this.elm.remove();
        }

        return Promise.resolve();
    }

    private async loadIFrame(): Promise<void> {
        const rnd = Math.random();
        const iframe = document.createElement("iframe");

        iframe.setAttribute("class", "banner");
        iframe.setAttribute("width", this.elmWidth);
        iframe.setAttribute("height", this.elmHeight);
        iframe.setAttribute("src", encodeURI(`${BS_IFRAME_URL}?id=${this.bsId}&rnd=${rnd}`));

        this.elm.appendChild(iframe);
        if (this.elmClass) {
            this.elm.setAttribute("class", this.elmClass);
        }

        return Promise.resolve();
    }

    static init(selectors: string): void {
        window.addEventListener("DOMContentLoaded", () => {
            document.querySelectorAll(selectors).forEach((value: HTMLElement) => {
                return new AdvertStatic(value);
            });
        });
    }
}

type Advert = AdvertStatic;
const Advert = AdvertStatic as typeof AdvertStatic & ((elm: HTMLElement) => Advert);

export default Advert;
