function onCopyAddLink(): void {
    const sourceLink = ` - Источник: ${document.location.href}`;
    const selection = window.getSelection();

    if (selection && selection.rangeCount) {
        const range = selection.getRangeAt(0);

        if (!range.commonAncestorContainer.textContent) {
            return;
        }

        const tempDiv = document.createElement("div");
        tempDiv.style.overflow = "hidden";
        tempDiv.style.width = "1px";
        tempDiv.style.height = "1px";
        tempDiv.style.position = "absolute";
        tempDiv.style.top = "-10000px";
        tempDiv.style.left = "-10000px";
        tempDiv.append(range.cloneContents(), sourceLink);

        document.body.append(tempDiv);

        const clonedRange = range.cloneRange();
        selection.removeAllRanges();

        const newRange = document.createRange();

        newRange.selectNode(tempDiv);
        selection.addRange(newRange);

        window.setTimeout(() => {
            selection.removeAllRanges();
            selection.addRange(clonedRange);
            tempDiv.remove();
        }, 0);
    }
}

export = onCopyAddLink;
